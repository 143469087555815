import { Injectable } from '@angular/core';

import { Constants } from 'src/app/constants';
import {
  AwsIotCoreService,
  MqttSubscription,
  MqttSendMessage,
  OnInitMqttService
} from '../iot/aws-iot-core.service';
import { MediaChangeDetectService } from '../../../pages/device/shared/services/media-change-detect.service';
import { BaseMqttService } from './base-mqtt.service';

@Injectable({
  providedIn: 'root'
})
export class CarouselMqttService
  extends BaseMqttService
  implements MqttSubscription, MqttSendMessage, OnInitMqttService
{
  private _showCarousel = false;

  constructor(
    protected awsIotCoreService: AwsIotCoreService,
    private mediaChangeDetectService: MediaChangeDetectService
  ) {
    super(awsIotCoreService);
  }

  async toggleCarousel(): Promise<void> {
    await this.publish(`${this._deviceId}/${Constants.CHANGE_CAROUSEL}`, {
      showCarousel: !this.showCarousel
    });
  }

  protected subscribe(): void {
    this.subscribeTopic(
      `${this._deviceId}/${Constants.CHANGE_CAROUSEL_FEED}`,
      async (data, error) => {
        if (error) {
          console.log('[Constants.CHANGE_CAROUSEL_FEED]:Error:', error);
        } else {
          this.showCarousel = data.value.showCarousel;
          console.log('Change Carousel Feed:', this.showCarousel)
          this.mediaChangeDetectService.changeDetection$.next(true);
        }
      }
    );
  }

  get showCarousel(): boolean {
    return this._showCarousel;
  }

  set showCarousel(value: boolean) {
    this._showCarousel = value;
  }
}
