import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { Constants } from '../../../constants';
import {
  AwsIotCoreService,
  MqttSendMessage,
  MqttSubscription,
  OnInitMqttService
} from '../iot/aws-iot-core.service';
import { BaseMqttService } from './base-mqtt.service';

@Injectable({
  providedIn: 'root'
})
export class PeopleCountMqttService
  extends BaseMqttService
  implements MqttSubscription, MqttSendMessage, OnInitMqttService
{
  private _count;
  public totalTime = 0;
  private colorSubject = new Subject<boolean>();
  color$ = this.colorSubject.asObservable();
  public color: boolean = false;

  constructor(
    protected awsIotCoreService: AwsIotCoreService
  ) {
    super(awsIotCoreService);
  }  

  protected subscribe(): void {
    this.subscribeTopic(
      `${this._deviceId}/${Constants.PEOPLE_COUNT}`,
      (data, error) => {
        if (error) {
          console.log(error);
        } else {
          this.count = data.value.peopleCount;
          console.log('People Count Feed:', this.count);
          this.changeColor();
        }
      }
    );
  }

  public changeColor(): void{
    this.totalTime = 0
    let intervalId = setInterval(() => {
      if (this.totalTime >= 3) {
        clearInterval(intervalId);
      }
      this.color = !this.color;
      this.colorSubject.next(this.color);
      this.totalTime += 1;
    }, 1000);
  }

  get count(): number {
    return this._count;
  }

  set count(value: number) {
    this._count = value;
  }
}