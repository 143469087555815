import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Constants } from '../../constants';
import { Authorization } from '../../shared/models/authorization.model';
import { Role } from '../../shared/models/role.model';
import { Company } from '../../shared/models/company.model';
import { Permission } from '../../shared/models/permission.model';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentAuthorizationSubject: BehaviorSubject<Authorization>;
  public currentAuthorization$: Observable<Authorization>;
  public currentUsername = '';
  public currentCompanyName = '';
  public hasCommander = true;
  public currentUserRole: Role[] = [];
  errorData;
  redirectUrl: string;

  constructor(private http: HttpClient) {
    this.currentAuthorizationSubject = new BehaviorSubject<Authorization>(
      JSON.parse(localStorage.getItem('currentAuthorization'))
    );
    this.currentAuthorization$ =
      this.currentAuthorizationSubject.asObservable();
    if (this.isLoggedIn()) {
      this.currentAuthorizationValue;
    }
  }

  public get currentAuthorizationValue(): Authorization {
    this.currentUserRole = this.currentAuthorizationSubject.value.roles;
    this.currentUsername = this.currentAuthorizationSubject.value.username;
    if (this.currentAuthorizationSubject.value.companyId) {
      const companies: Company[] =
        this.currentAuthorizationSubject.value.companies;
      for (const company of companies) {
        if (company.id === this.currentAuthorizationSubject.value.companyId) {
          this.currentCompanyName = company.name;
        }
      }
    }
    return this.currentAuthorizationSubject.value;
  }

  login(username: string, password: string): any {
    const headers = { 'Content-Type': 'application/json' };
    const options = { headers };
    const body = { username, password };
    return this.http.post(Constants.LOGIN, body, options).pipe(
      map((authorization: Authorization) => {
        if (authorization) {
          localStorage.setItem(
            'currentAuthorization',
            JSON.stringify(authorization)
          );
          this.currentAuthorizationSubject.next(authorization);
        }

        return authorization;
      }),
      catchError(this.handleErrorAuthentication)
    );
  }

  protected handleErrorAuthentication(error: any): Observable<any> {
    return throwError(error);
  }

  loginCompany(companyId: string): any {
    const authorization: Authorization = JSON.parse(
      localStorage.getItem('currentAuthorization')
    );
    const headers = {
      'Content-Type': 'application/json',
      Authorization: authorization.token
    };
    const options = { headers };
    const body = { companyId };

    return this.http.post(Constants.LOGIN_COMPANY, body, options).pipe(
      map((auth: Authorization) => {
        if (auth) {
          localStorage.setItem('currentAuthorization', JSON.stringify(auth));
          this.currentAuthorizationSubject.next(auth);
        }
        return auth;
      })
    );
  }

  loginGuest(token: string): any {
    // Clear currentAuthorization object
    this.clearAuthorization();
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    };
    const options = { headers };
    const body = {};

    return this.http.post(Constants.LOGIN_GUEST, body, options).pipe(
      map((authorization: Authorization) => {
        if (authorization) {
          localStorage.setItem(
            'currentAuthorization',
            JSON.stringify(authorization)
          );
          this.currentAuthorizationSubject.next(authorization);
        }
        return authorization;
      })
    );
  }

  forgot(username: string): any {
    const headers = { 'Content-Type': 'application/json' };
    const options = { headers };
    const body = { username };

    return this.http.post(Constants.FORGOT_PASSWORD, body, options).pipe(
      map((message: any) => {
        return message;
      })
    );
  }

  isLoggedIn(): boolean {
    return !!localStorage.getItem('currentAuthorization');
  }

  clearAuthorization(): void {
    localStorage.removeItem('currentAuthorization');
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    this.errorData = {
      errorTitle: 'Oops! Request for document failed',
      errorDesc: 'Something bad happened. Please try again later.'
    };
    return throwError(this.errorData);
  }

  hasPermission(authGroup: string[]): boolean {
    if (authGroup == undefined) {
      return false;
    } else if (
      this.currentAuthorizationValue.permissions != null &&
      this.currentAuthorizationValue.permissions.find(
        (permission: Permission) => {
          return authGroup.find((group) => group === permission.name);
        }
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  initializePermissions(): Promise<unknown> {
    return new Promise<void>((resolve, reject) => {
      resolve();
    });
  }
}
