import { Component, OnInit } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';
import { cloneDeep } from 'lodash';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-repeat-section',
  template: `
    <div *ngIf="this.model.length > 0">
      <div class="table-responsive">
        <table
          class="table table-striped table-bordered full-data-table"
          [mfData]="this.model"
          #mf="mfDataTable"
          [mfRowsOnPage]="rowsOnPage"
          [(mfSortBy)]="sortBy"
          [(mfSortOrder)]="sortOrder"
        >
          <thead>
            <tr>
              <th *ngFor="let field of field.fieldArray.fieldGroup">
                <mfDefaultSorter by="{{ field.key }}">
                  {{ field.templateOptions.label }}
                </mfDefaultSorter>
              </th>
              <th *ngIf="to.canDelete ? true : false">Ação</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of this.model; let modelIndex = index">
              <td
                *ngFor="let field of field.fieldArray.fieldGroup; let i = index"
              >
                {{ this.model[modelIndex][field.key] }}
              </td>
              <td *ngIf="to.canDelete ? true : false">
                <a
                  norepeat="true"
                  (click)="openConfirmsSwal(modelIndex)"
                  class="crm-action-delete text-muted text-danger"
                  *ngIf="to.required ? modelIndex !== 0 : true"
                  ><i class="icofont icofont-delete-alt"></i
                ></a>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th *ngFor="let field of field.fieldArray.fieldGroup">
                <mfDefaultSorter by="{{ field.key }}">
                  {{ field.templateOptions.label }}
                </mfDefaultSorter>
              </th>
              <th *ngIf="to.canDelete ? true : false">Ação</th>
            </tr>
            <tr>
              <td colspan="7">
                <mfBootstrapPaginator
                  class="pagination-main f-right"
                ></mfBootstrapPaginator>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
    <div *ngIf="model.length === 0" class="col-12 text-center">
      {{ to.messageWithoutObjects }}
    </div>
    <div *ngIf="showForm" class="subform">
      <form [formGroup]="singleForm">
        <formly-form
          [model]="mdl"
          [fields]="singleField"
          [form]="singleForm"
          [options]="ops"
        >
          <div class="col-sm-12 centered-content text-center">
            <div class="btn-group mt-10">
              <button
                class="btn btn-outline-primary"
                (click)="hideForm()"
                *ngIf="showForm"
              >
                Cancelar
              </button>
              <button
                class="btn btn-primary"
                color="accent"
                (click)="save(mdl)"
                type="button"
                [disabled]="!form.valid"
              >
                Salvar
              </button>
              <!--<button type="submit" class="btn btn-primary"
                      [disabled]="!form.valid">Salvar
              </button>-->
            </div>
          </div>
        </formly-form>
      </form>
    </div>
    <div style="margin:10px 0;" class=" text-right" *ngIf="!showForm">
      <button
        class="btn btn-outline-primary"
        [disabled]="to.disabledAdd"
        type="button"
        (click)="addItem()"
      >
        {{ to.addText }}
      </button>
    </div>
  `,
  styleUrls: ['./repeat-type.component.scss']
})
export class RepeatTypeComponent extends FieldArrayType implements OnInit {
  rowsOnPage = 10;
  sortBy = '';
  sortOrder = 'desc';
  title: any = '';

  showForm = false;
  singleForm = new FormGroup({});
  singleField = [];
  ops: any = {};
  mdl = {};

  removeElement(element): void {
    const mustDelete = confirm(
      'Ao remover este item, todos os itens associados serão apagados. Deseja continuar?'
    );
    if (mustDelete) {
      this.remove(element);
    }
  }

  openConfirmsSwal(element) {
    Swal.fire({
      title: 'Remover item selecionado.',
      text: 'Ao remover este item, todos os itens associados serão apagados. Deseja continuar?',
      showCancelButton: true,
      confirmButtonColor: '#ff0000',
      cancelButtonColor: 'Não',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.to.deleteItem(this.model[element].id);
        this.remove(element);
      }
    });
  }

  ngOnInit(): void {
    this.singleField = cloneDeep(this.field.fieldArray.fieldGroup);
  }

  addItem(): void {
    this.showForm = true;
  }

  hideForm(): void {
    this.showForm = false;
    this.ops.resetModel();
  }

  save(model): void {
    this.add(null, model);
    this.hideForm();
    this.to.submitItem();
  }
}
