import { Injectable } from '@angular/core';
import { Constants } from '../../../constants';
import {
  AwsIotCoreService,
  MqttSendMessage,
  MqttSubscription,
  OnInitMqttService
} from '../iot/aws-iot-core.service';
import { BaseMqttService } from './base-mqtt.service';
import { ScreenshotService } from '../../../pages/screenshot/services/screenshot.service';
import { Screenshot } from '../../../shared/models/screenshot.model';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HighResolutionScreenShotMqttService
  extends BaseMqttService
  implements MqttSubscription, MqttSendMessage, OnInitMqttService
{
  private highResImg$ = new Subject<string>();

  constructor(
    protected awsIotCoreService: AwsIotCoreService,
    protected screenshotService: ScreenshotService
  ) {
    super(awsIotCoreService);
  }

  async takeHighResolutionScreenshot(): Promise<void> {
    await this.publish(
      `${this._deviceId}/${Constants.HIGH_RESOLUTION_SCREENSHOT}`,
      {
        takeHighResolutionScreenShot: true
      }
    );
  }

  getHighResImg(): Observable<string> {
    return this.highResImg$.asObservable();
  }

  protected subscribe(): void {
    this.subscribeTopic(
      `${this._deviceId}/${Constants.HIGH_RESOLUTION_SCREENSHOT_FEED}`,
      (data, error) => {
        if (error) {
          console.error(
            '[Constants.HIGH_RESOLUTION_SCREENSHOT_FEED]:Error:',
            error
          );
        } else {
          const screenshot = new Screenshot();
          screenshot.deviceId = data.value.deviceId!;
          screenshot.image = data.value.imageUrl!;

          this.screenshotService.createOnlyData(screenshot).subscribe(() => {
            this.screenshotService
              .getScreenshotMetadataByDeviceId(screenshot.deviceId)
              .toPromise()
              .then(async (response: Screenshot[]) => {
                const filtered: Screenshot = response.filter(
                  (screenshotRcvd: Screenshot) => screenshotRcvd.image.includes(screenshot.image)
                )[0];
                this.highResImg$.next(filtered?.value);
              });
          });
        }
      }
    );
  }
}
