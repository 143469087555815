import { Injectable } from '@angular/core';
import { BaseMqttService } from './base-mqtt.service';
import {
  AwsIotCoreService,
  MqttSendMessage,
  MqttSubscription,
  OnInitMqttService
} from '../iot/aws-iot-core.service';
import { Constants } from 'src/app/constants';
import { MediaChangeDetectService } from 'src/app/pages/device/shared/services/media-change-detect.service';

@Injectable({
  providedIn: 'root'
})
export class SurveyFormMqttService
  extends BaseMqttService
  implements MqttSubscription, MqttSendMessage, OnInitMqttService
{
  private _showSurveyForm = false;

  constructor(
    protected awsIotCoreService: AwsIotCoreService,
    private mediaChangeDetectService: MediaChangeDetectService
  ) {
    super(awsIotCoreService);
  }

  async toggleSurveyForm(): Promise<void> {
    await this.publish(`${this._deviceId}/${Constants.CHANGE_SURVEY_FORM}`, {
      showSurveyForm: this.showSurveyForm
    });
  }

  protected subscribe(): void {
    this.subscribeTopic(
      `${this._deviceId}/${Constants.CHANGE_SURVEY_FORM_FEED}`,
      (data, error) => {
        if (error) {
          console.error('[Constants.CHANGE_SURVEY_FORM_FEED]:Error:', error);
        } else {
          this.showSurveyForm = data.value.showSurveyForm;
          this.mediaChangeDetectService.changeDetection$.next(true);
        }
      }
    );
  }

  get showSurveyForm(): boolean {
    return this._showSurveyForm;
  }

  set showSurveyForm(value: boolean) {
    this._showSurveyForm = value;
  }
}
