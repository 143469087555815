import { Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Constants } from '../../../../app/constants';
import { BaseMqttService } from './base-mqtt.service';
import { AwsIotCoreService, MqttSendMessage, MqttSubscription, OnInitMqttService } from '../iot/aws-iot-core.service';
import { SetRbvDeviceMqttStatus, SetRbvCoreMqttStatus } from '../../../pages/device/device-control/store/actions/participants.actions';

@Injectable({
  providedIn: 'root'
})
export class CheckDeviceMqttConnectionService 
  extends BaseMqttService
  implements MqttSubscription, MqttSendMessage, OnInitMqttService
{
  constructor(
    protected awsIotCoreService: AwsIotCoreService,
    protected readonly store: Store
  ) { 
    super(awsIotCoreService)
  }

  protected subscribe(): void {
    this.subscribeTopic(
      `${this._deviceId}/${Constants.CHECK_DEVICE_MQTT_CONNECTION}`,
      (data, error) => {
        if (error) {
          // Assign false value for device MQTT connection in case of failure.
          console.error(`Failed to get MQTT connection status from ID device: ${this._deviceId}`);
          this.store.dispatch(new SetRbvCoreMqttStatus(false));
          this.store.dispatch(new SetRbvDeviceMqttStatus(false));
        } else {
          // Check which repository is the source of the response and set connection status to true.
          const repository = data.value.repository;
          if (repository === 'RBV-DEVICE') {
            this.store.dispatch(new SetRbvDeviceMqttStatus(true));
          } else if (repository === 'ROBOVISOR_CORE') {
            this.store.dispatch(new SetRbvCoreMqttStatus(true));
          }
        }
      }
    );
  }

  public async checkDeviceConnection(): Promise<void> {
    await this.publish(`${this._deviceId}/${Constants.CHECK_DEVICE_MQTT_CONNECTION}`, {
      repository: 'RBV-CTRL'
    });
  }

  private unsubscribeCheckDevice(): void {
    this.awsIotCoreService.unsubscribe(`${this._deviceId}/${Constants.CHECK_DEVICE_MQTT_CONNECTION}`);
  }

  ngOnDestroy(): void {
    this.unsubscribeCheckDevice();
  }
}
