import { Injectable } from '@angular/core';
import {
  AwsIotCoreService,
  MqttSendMessage,
  MqttSubscription,
  OnInitMqttService
} from '../iot/aws-iot-core.service';
import { Constants } from '../../../constants';
import { MediaChangeDetectService } from '../../../pages/device/shared/services/media-change-detect.service';
import { BaseMqttService } from './base-mqtt.service';

export namespace StatusMqttService {
  export type Mqtt = {
    robot: StatusMqttService.Robot;
  };
  export type WebRtc = {
    timestamp: number;
    value: number;
  };
  export type Robot = {
    value: number;
  };
}

export type Latency = {
  mqtt: StatusMqttService.Mqtt;
  webrtc: StatusMqttService.WebRtc;
};

export type Version = {
  core: string;
  monitor: string;
  conf: string;
  webc: string;
  webd: string;
};

export type Temperature = {
  value: string;
};

export type WiFi = {
  ssid: string;
  strength: number;
  countDelay: number;
  level: string | undefined;
};

export type Battery = {
  voltage: string;
  current: string;
  power: string;
  time: string;
  state: string;
};

export type MqttMessage = {
  msg: string;
};

export type Obstaculo = {
  colisaoDianteira: boolean;
  distanciaDianteira: number;
  colisaoTraseira: boolean;
  distanciaTraseira: number;
  colisaoEsquerda: boolean;
  distanciaEsquerda: number;
  colisaoDireita: boolean;
  distanciaDireita: number;
};

export type Odometer = {
  distance: number;
  initialDistance: number | null;
};

@Injectable({
  providedIn: 'root'
})
export class StatusMqttService
  extends BaseMqttService
  implements MqttSubscription, MqttSendMessage, OnInitMqttService
{
  private _latency: Latency;

  private _version: Version;

  private _wifi: WiFi;

  private _temperature: Temperature;

  private _battery: Battery;

  private _mqttMsg: MqttMessage;

  private _obstaculo: Obstaculo;

  private _intervalId: number;

  private _odometer: Odometer;

  private _latency_threshold = 450; // in ms

  constructor(
    protected awsIotCoreService: AwsIotCoreService,
    private mediaChangeDetectService: MediaChangeDetectService
  ) {
    super(awsIotCoreService);
    this.initData();
  }

  private initData(): void {
    this._latency = {
      mqtt: {
        robot: {
          value: -1
        }
      },
      webrtc: { timestamp: -1, value: -1 }
    };

    this._version = {
      core: '---',
      monitor: '---',
      conf: '---',
      webc: '---',
      webd: '---'
    };

    this._wifi = {
      ssid: '',
      strength: 0,
      countDelay: 0,
      level: undefined
    };

    this._temperature = {
      value: ''
    };

    this._battery = {
      voltage: '0.0',
      current: '0.0',
      power: '0.0',
      time: '0.0',
      state: '0.0'
    };

    this._mqttMsg = {
      msg: '---'
    };

    this._obstaculo = {
      colisaoDianteira: false,
      distanciaDianteira: 100,
      colisaoTraseira: false,
      distanciaTraseira: 100,
      colisaoEsquerda: false,
      distanciaEsquerda: 100,
      colisaoDireita: false,
      distanciaDireita: 100
    };

    this._odometer = {
      distance: 0,
      initialDistance: null
    };

    this._intervalId = undefined;
    this._connectionId = undefined;
    this._deviceId = undefined;
  }

  protected intervalBatteryInit(callback: () => void, interval: number) {
    this._intervalId = Number(setTimeout(callback, interval));
  }

  protected subscribe(): void {
    this.subscribeTopic(`${this._deviceId}/status/battery`, (data, error) => {
      if (error) {
        console.log('[battery]:Error:', error);
      } else {
        if (this._intervalId) {
          clearTimeout(this._intervalId);
        }
        this._battery.voltage = data.value.voltage;
        this._battery.current = data.value.current;
        this._battery.power = `${
          parseFloat(data.value.current) * parseFloat(data.value.voltage)
        }`;
        this._battery.time = data.value.time;
        this._battery.state = data.value.state;
        this.mediaChangeDetectService.changeDetection$.next(true);
        this.intervalBatteryInit(() => {
          this._battery.voltage = undefined;
          this._battery.current = undefined;
          this._battery.power = undefined;
          this._battery.time = undefined;
          this._battery.state = undefined;
        }, 60000);
      }
    });
    this.subscribeTopic(`${this._deviceId}/status/wifi`, (data, error) => {
      if (error) {
        console.log('[wifi]:Error:', error);
      } else {
        this._wifi.strength = data.value.strength;
        this._wifi.ssid = data.value.ESSID;
        this._wifi.countDelay = 0;

        if (data.value.strength >= Constants.WIFI_STRENGTH_HIGH) {
          this._wifi.level = 'high';
        } else if (
          data.value.strength < Constants.WIFI_STRENGTH_HIGH &&
          data.value.strength >= Constants.WIFI_STRENGTH_MEDIUM
        ) {
          this._wifi.level = 'medium';
        } else if (
          data.value.strength < Constants.WIFI_STRENGTH_MEDIUM &&
          data.value.strength >= Constants.WIFI_STRENGTH_LOW
        ) {
          this._wifi.level = 'low';
        } else if (data.value.strength < Constants.WIFI_STRENGTH_LOW) {
          this._wifi.level = 'very_low';
        } else {
          this._wifi.level = undefined;
        }
        this.mediaChangeDetectService.changeDetection$.next(true);
      }
    });

    this.subscribeTopic(
      `${this._deviceId}/status/raspi_temperature`,
      (data, error) => {
        if (error) {
          console.log('[temp]:Error:', error);
        } else {
          this._temperature.value = data.value.temperature;

          this.mediaChangeDetectService.changeDetection$.next(true);
        }
      }
    );

    this.subscribeTopic(
      `${this._deviceId}/status/version/core`,
      (data, error) => {
        if (error) {
          console.log('[version]:Error:', error);
        } else {
          this._version.core = data.value.version;

          this.mediaChangeDetectService.changeDetection$.next(true);
        }
      }
    );

    this.subscribeTopic(
      `${this._deviceId}/status/version/monitor`,
      (data, error) => {
        if (error) {
          console.log('[version]:Error:', error);
        } else {
          this._version.monitor = data.value.version;

          this.mediaChangeDetectService.changeDetection$.next(true);
        }
      }
    );

    this.subscribeTopic(`${this._deviceId}/status/msg`, (data, error) => {
      if (error) {
        console.log('[mqtt msg]:Error:', error);
      } else {
        this._mqttMsg.msg = data.value.msg;

        this.mediaChangeDetectService.changeDetection$.next(true);
      }
    });

    this.subscribeTopic(
      `${this._deviceId}/${Constants.PING}/robot`,
      (data, error) => {
        if (error) {
          console.log('[Constants.PING]:Error:', error);
        } else {
          this._latency.mqtt.robot.value = 1e-6 * data.value.delta;

          this.mediaChangeDetectService.changeDetection$.next(true);
        }
      }
    );

    this.subscribeTopic(`${this._deviceId}/status/obstaculo`, (data, error) => {
      if (error) {
        console.log('[mqtt msg]:Error:', error);
      } else {
        this._obstaculo = data.value;

        this.mediaChangeDetectService.changeDetection$.next(true);
      }
    });

    this.subscribeTopic(`${this._deviceId}/status/odometer`, (data, error) => {
      if (error) {
        console.log('[mqtt msg]:Error:', error);
      } else {
        if (this._odometer.initialDistance === null) {
          this._odometer.initialDistance = data.value.odometer;
          this._odometer.distance = 0;
        } else {
          this._odometer.distance =
            data.value.odometer - this._odometer.initialDistance;
        }
      }
    });

    // Inform device of new participant connecting, so it'll send some relevant data immediately
    this.schedulePublish(`${this._deviceId}/${Constants.NEW_PARTICIPANT}`, {
      new_listener: 1
    });
  }

  get latency(): Latency {
    return this._latency;
  }

  get version(): Version {
    return this._version;
  }

  get wifi(): WiFi {
    return this._wifi;
  }

  get battery(): Battery {
    return this._battery;
  }

  get temperature(): Temperature {
    return this._temperature;
  }

  get mqttMsg(): MqttMessage {
    return this._mqttMsg;
  }

  get odometer(): Odometer {
    return this._odometer;
  }

  get mqttStatus(): 'slow' | 'normal' {
    if (this._latency.mqtt.robot.value >= this._latency_threshold)
      return 'slow';
    else return 'normal';
  }

  clearData = () => this.initData();
}
